<template>
<b-card no-body>
  <validation-observer
    v-slot="{ handleSubmit }" ref="VForm"
  >
    <b-form @submit.prevent="handleSubmit(doSubmit())">
      <b-card-header>
        <b-row>
          <b-col lg="8">
            <h5 class="card-title">{{ $parent.isAdd?'Tambah':'Edit' }} {{ $parent.pageTitle }}</h5>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col lg="10">
            <b-row>
              <b-col lg="6">
                <b-form-group>
                  <label>Kode<span class="text-danger mr5">*</span></label>
                  <b-form-input type="text" class="form-control" v-model="row.mh_code" placeholder="e.g 0001" :formatter="$parent.code" />
                  <VValidate 
                    name="Kode" 
                    v-model="row.mh_code" 
                    :rules="toValidate(mrValidation.mh_code)"
                  />
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <b-form-group>
                  <label>Nama<span class="text-danger mr5">*</span></label>
                  <b-form-input :formatter="$parent.normalText" placeholder="Nama" type="text" class="form-control" v-model="row.mh_name"/>
                  <VValidate 
                    name="Nama" 
                    v-model="row.mh_name" 
                    :rules="toValidate(mrValidation.mh_name)"
                  />
                </b-form-group>
              </b-col>
            </b-row>
                  
            <b-row class="mt-3">
              <b-col lg="12">
                <label for="">Komponen</label>
                <table class="table table-hover table-bordered">
                  <thead>
                    <tr class="table-secondary">
                      <th>Nama</th>
                      <th width="100">Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v,k) in row.mh_component" :key="k">
                      <td>
                      <input type="text" v-model="row['mh_component'][k]['name']" class="form-control" placeholder="e.g. Pertolongan Pertama">
                      <VValidate 
                        :name="'Name Komponen '+(k+1)" 
                        v-model="row['mh_component'][k]['name']" 
                        :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, required:1, min:2, max: 512}"
                      />
                      </td>
                      <td class="text-center">
                        <i v-if="row.mh_component.length > 1" @click="removeRowComponent(k)" class="icon-trash"></i>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="4">
                        <div class="text-center">
                          <button type="button" @click="addRowComponent()" class="btn bg-blue-400 btn-labeled btn-labeled-left"><b><i class="icon-plus-circle2"></i></b> Tambah Field</button>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </b-col>
            </b-row>

            <b-row class="mt-3">
              <b-col lg="12">
                <label for="">Kejadian</label>
                <table class="table table-hover table-bordered">
                  <thead>
                    <tr class="table-secondary">
                      <th>Nama</th>
                      <th width="100">Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v,k) in row.mh_kejadian" :key="k">
                      <td>
                      <input type="text" v-model="row['mh_kejadian'][k]['name']" class="form-control" placeholder="e.g. Pertolongan Pertama">
                      <VValidate 
                        :name="'Name Kejadian '+(k+1)" 
                        v-model="row['mh_kejadian'][k]['name']" 
                        :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, required:1, min:2, max: 512}"
                      />
                      </td>
                      <td class="text-center">
                        <i v-if="row.mh_kejadian.length > 1" @click="removeRowKejadian(k)" class="icon-trash"></i>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="4">
                        <div class="text-center">
                          <button type="button" @click="addRowKejadian()" class="btn bg-blue-400 btn-labeled btn-labeled-left"><b><i class="icon-plus-circle2"></i></b> Tambah Field</button>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="6">
                <b-form-group class="mt-3">
                  <label>Status <span class="text-danger mr5">*</span></label>
                  <b-form-radio-group
                    :options="Config.mr.StatusOptions"
                    v-model="row[$parent.statusKey]"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-footer>
        <b-row>
          <b-col lg="12" class="text-right">
            <button @click="$router.back()" type="button" class="btn  btn-rounded  btn-default mr-3">Batal</button>
            <b-button type="submit" variant="primary" class="btn-rounded">Simpan</b-button>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-form>
  </validation-observer>
</b-card>
</template>

<script>
import $ from 'jquery'
const _ = global._

export default{
  props:{
    row:Object,
    mrValidation:Object,
    Config:Object
  },
  methods: {
    toValidate(val){
      return {...val}
    },

    addRowComponent(){
      let payload = {
        "name":""
      }
      this.row.mh_component.push(payload)
    },
    removeRowComponent(k){
      this.row.mh_component.splice(k,1)      
    },

    addRowKejadian(){
      let payload = {
        "name":""
      }
      this.row.mh_kejadian.push(payload)
    },
    removeRowKejadian(k){
      this.row.mh_kejadian.splice(k,1)      
    },

    isSame(data){
      let isSameCmp = 0
      let dmpCmp = []    
      for(let i = 0; i < (data||[]).length; i++){
        let idx = dmpCmp.findIndex(x => x == data[i]['name'])
        if(idx == -1){
          dmpCmp.push(data[i]['name'])
        }else{
          isSameCmp += 1
        }
      }
      return isSameCmp
    },

    doSubmit(){
      let isSameCmp = this.isSame(this.row.mh_component)
      let isSameKejadian = this.isSame(this.row.mh_kejadian)

      if(isSameCmp){
        return this.$swal({
          title: "Tidak Dapat Lanjut",
          html: "Komponen Tidak Boleh Sama",                
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
      
      if(isSameKejadian){
        return this.$swal({
          title: "Tidak Dapat Lanjut",
          html: "Kejadian Tidak Boleh sama",                
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }

      return this.$parent.doSubmit(
          "/do/"+this.$parent.modulePage,
          _.assign({type:this.$parent.isAdd?'add':'update'}, {id: (this.$parent.pageId||this.$parent.pageSlug)}, _.clone(this.row)), 
          (type, resp)=>{
            if(type=='success'){
              this.$router.push({name:this.$route.name}).catch(()=>{})
            }else{
              if(resp.response.status==422) {
                if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                  var msg = ""
                  $.each(Object.keys(resp.response.data),(i,v)=>{
                    msg = msg+" <br/>"+v+" : "+_.values(resp.response.data[v])
                  })
                  return this.$swal("Blocked With Validation!", msg)
                }else{
                  return this.$swal("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              }else if(resp.response.status==400){

                return this.$swal({
                  title: resp.response.data.title||resp.response.data.message,
                  html: resp.response.data.title?resp.response.data.message:"",                
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
          'POST', 'VForm', this.$refs
      )
    }
  },
}
</script>